/* website: 3220-mercedes-benz-quebec1
 * created at 2020-10-15 14:45 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/mercedes-benz.scss";
@import "../templates/groups/parkavenue.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__mercedes-benz-quebec{
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            padding: 15px 14px !important;
            text-transform: uppercase !important;
        }
    }
  }